import Preloader from 'components/Preloader'
import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/styles/normalize.css'
import './index.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistratration'
import { availableNewVersion } from './api/apollo/state'
import * as Sentry from '@sentry/react'
import Main from './Main'
//import reportWebVitals from './reportWebVitals';
require('dotenv').config()
Sentry.init({ dsn: 'https://eabadb7edf7443e3ab0ae4743b4b6d3d@sentry.teeko.io/82' })

ReactDOM.render(
	<React.StrictMode>
		<div>
			<Suspense fallback={<Preloader />}>
				<Main />
			</Suspense>
		</div>
	</React.StrictMode>,
	document.getElementById('root'),
)
// @ts-ignore
const ignored = window.self.__WB_MANIFEST || {}

serviceWorkerRegistration.register({
	onUpdate: (registration: any) => {
		availableNewVersion(true)
	},
})
//reportWebVitals(console.log);
